//TEST CASE
const json = '{"thumb":"thumb","src":"12_stein2","prefix":"prefix"}';
const obj = JSON.parse(json);
//TEST CASE

var data = require("./images.json");
const jsonFile = JSON.parse(JSON.stringify(data));
const jsonObject = jsonFile.images;

//thumb
const thumH = 200;
const thumW = 200;
//url builder
const webPrefix = "http://isabelle-pruefling.de/img/";

//einstellungen
const showPrice = true;
const showVerkauft = false;
//

function generateDescription(desc, size, material, price, sold) {
  var outStr = desc + " " + size + " " + material;
  if (showPrice) {
    outStr = outStr + " " + price;
  }
  if (showVerkauft) {
    outStr = outStr + " " + sold;
  }

  return outStr;
}

function autoThumb(props) {
  return webPrefix + props + "/" + props + "_thumb.png";
}

function autoSrc(props, size = 0) {
  var endSize = "";
  switch (size) {
    case 0:
      endSize = "_800x800.png";
      break;
    case 1:
      endSize = "_400x400.png";
      break;
    default:
      endSize = "_800x800.png";
  }

  //url for src
  return webPrefix + props + "/" + props + endSize;
}

function cleanName(props) {
  return props.replace(/_/g, " ");
}

console.log(jsonFile.images[0].id);

var images = [
  {
    thumbnail: autoThumb(obj.src),
    src: autoSrc(jsonFile.images[0].id, 0),
    thumbnailWidth: thumW,
    thumbnailHeight: thumH,
  },
];

for (let i = 0; i < jsonObject.length; i++) {
  var imageSkeleton = {
    thumbnail: autoThumb(jsonObject[i].src),
    src: autoSrc(jsonObject[i].src, 0),
    thumbnailWidth: thumW,
    thumbnailHeight: thumH,
    caption: generateDescription(
      jsonObject[i].beschreibung,
      jsonObject[i].maße,
      jsonObject[i].material,
      jsonObject[i].preis,
      jsonObject[i].verkauft
    ),
  };
  images.push(imageSkeleton);
}

export default images;

//   //added 5.5.2022
//   {
//     thumbnail: autoThum("11_stein1"),
//     src: autoSrc("11_stein1"),
//     thumbnailWidth: 200,
//     thumbnailHeight: thumH,
//     caption: cleanName("Steinberger See II"),
//   },

//   {
//     thumbnail: autoThum("12_stein2"),
//     src: autoSrc("12_stein2"),
//     thumbnailWidth: 200,
//     thumbnailHeight: thumH,
//     caption: cleanName("Steinberger See I"),
//   },

//   {
//     thumbnail: autoThum("13_go1"),
//     src: autoSrc("13_go1"),
//     thumbnailWidth: 150,
//     thumbnailHeight: thumH,
//     caption: cleanName("Gogotte I"),
//   },

//   {
//     thumbnail: autoThum("14_go2"),
//     src: autoSrc("14_go2"),
//     thumbnailWidth: 150,
//     thumbnailHeight: thumH,
//     caption: cleanName("Gogotte II"),
//   },

//   {
//     thumbnail: autoThum("15_durst"),
//     src: autoSrc("15_durst"),
//     thumbnailWidth: 150,
//     thumbnailHeight: thumH,
//     caption: cleanName("Durst"),
//   },

//   {
//     thumbnail: autoThum("16_e1"),
//     src: autoSrc("16_e1"),
//     thumbnailWidth: 150,
//     thumbnailHeight: thumH,
//     caption: cleanName("Esel 1"),
//   },

//   {
//     thumbnail: autoThum("17_e2"),
//     src: autoSrc("17_e2"),
//     thumbnailWidth: 150,
//     thumbnailHeight: thumH,
//     caption: cleanName("Esel 2"),
//   },

//   {
//     thumbnail: autoThum("18_mo"),
//     src: autoSrc("18_mo"),
//     thumbnailWidth: 200,
//     thumbnailHeight: thumH,
//     caption: cleanName("Mops"),
//   },

//   {
//     thumbnail: autoThum("19_fro"),
//     src: autoSrc("19_fro"),
//     thumbnailWidth: 200,
//     thumbnailHeight: thumH,
//     caption: cleanName("Frosch"),
//   },
