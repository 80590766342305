import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function NavScrollExample() {
  return (
    <Navbar expand="lg" className="navPad">

      <Navbar.Text href="#home">ISABELLE PRÜFLING</Navbar.Text>
        <Navbar.Toggle aria-controls="navbarScroll" />
        {/* // wenn das ding ausgeklappt wird, bestimmt maxHeight wie weit das nach unten geht  */}
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '10em' }}
            navbarScroll
          >
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="blog.html">Blog</Nav.Link>
            <Nav.Link href="imprint.html">Impressum</Nav.Link>
          </Nav>
        </Navbar.Collapse>

    </Navbar>
  );
}

export default NavScrollExample;