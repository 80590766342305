import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Paintings from "./pages/Main";

import { render } from "react-dom";
import { showVersion } from "./showVersion";

import { Helmet } from "react-helmet";

export default function App() {
  return (
      <Paintings></Paintings>
  );
}

showVersion();
render(<App />, document.getElementById("index"));
