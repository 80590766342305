import React from "react";
import Gallery from "react-grid-gallery";
import { Outlet } from "react-router-dom";
import "../style/paintings.css";

import { IMAGES as images } from "../img/images";
import { default as testImages } from "../img/images2";
import Navbar2 from "./Navbar2";
import Navbar from "./Navbar";
import Navbar3 from "./Navbar3";


import { Button } from "react-bootstrap";

const ifImgOpen = false;
const imIndex = 0;

const paintings = () => {
  return (
    <>
      {/* <div className="row">
        <div className="col-sm-3 navPad"> */}


      {/* </div> */}
      {/* 
        <div className="col"></div>
      </div> */}

      <div className="row" id="site-all">

        <div className="navPad">
          <Navbar3></Navbar3>
        </div>

        <div className="col-sm-3 ">



          <img
            src={"https://isabelle-pruefling.de/static/media/new_avatar.51be45f0ce58aa1ff5c6.jpg"}
            alt="Artist"
            width="200em"
            height="200em"
            className="left rounded headshotImg"
          />

<div >
          <p className="infoText">
Acryl und Ölmalerei

          </p>

          <p className="infoText">Ich male alles , was mir gefällt  und wie es mir gefällt</p>
          
          <table>
            <tbody className="infoText">
              <tr>
                <img src={"https://isabelle-pruefling.de/static/media/mail.ceed57e9483391a79813f313693eb103.svg"} alt="MAIL - LOGO" />
                <td>
                  {" "}
                  <a href="mailto:info@isabelle-pruefling.de">
                    info@isabelle-pruefling.de
                  </a>
                </td>
              </tr>
              <tr>
                <img src={"https://isabelle-pruefling.de/static/media/instagram.4d969dd141d389ed7a2fe7584b56d681.svg"} alt="IG - LOGO" />
                <td>
                  {" "}
                  <a href="https://www.instagram.com/isa123_belle">
                    @isa123_belle
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          {/* </div> */}
        </div>
</div>
        <div className="col">
          {/* STYLE FOR THE IMAGE VIEWER */}

          <div className="imgBox ">
            <Gallery
              //scales up the pictures
              rowHeight={400}
              // margin, höhe abstand zwischen den Bildern
              margin={5}
              images={images}
              // lightboxWidth={2000}
              enableLightbox={true}
              enableImageSelection={false}
              currentImage={imIndex}
              // Optional. Allow users to exit the lightbox by clicking the backdrop.
              backdropClosesModal={true}
              isOpen={ifImgOpen}
              // Optional. Customize separator in the image count.
              imageCountSeparator={" / "}
              preloadNextImage={true}
              enableKeyboardInput={true}
              // onClickImage={clickOnImage}
            />
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default paintings;
