

const thumH = 200;
const thumW = 150;

var w = window.innerWidth;
var h = window.innerHeight;

var webPrefix = "http://isabelle-pruefling.de/img/";

function cleanName(props) {
  return props.replace(/_/g, " ");
}

function autoThum(props) {
   //return webPrefix + props + "/" + props + "_thumb.png";
  return webPrefix + props + "/" + props + "_400x400.png";

}

function autoSrc(props) {
  return webPrefix + props + "/" + props + "_800x800.png";
}


export const IMAGES = [
// 1. Pilze  
  {
    thumbnail: autoThum("33_pilz"),
    src: autoSrc("33_pilz"),
    thumbnailWidth: thumW,
    thumbnailHeight: thumH,
    caption: cleanName("Pilzsolo"),
  },
  {
    thumbnail: autoThum("20_ausf"),
    src: autoSrc("20_ausf"),
    thumbnailWidth: thumW,
    thumbnailHeight: thumH,
    caption: cleanName("Ausflug"),
  },
  {
    thumbnail: autoThum("23_erleu"),
    src: autoSrc("23_erleu"),
    thumbnailWidth: thumW,
    thumbnailHeight: thumH,
    caption: cleanName("Erleuchtung"),
  },
//  Pilze  ENDE 
  
  
{
  thumbnail: autoThum("5_aus"),
  src: webPrefix + "5_aus/5_aus_800x800.png",
  thumbnailWidth: thumW,
  thumbnailHeight: thumH,
  caption: cleanName("Ausblick_80x90cm_Acryl_LW"),
},

{
  thumbnail: autoThum("6_maria"),
  src: webPrefix + "6_maria/6_maria_800x800.png",
  thumbnailWidth: thumW,
  thumbnailHeight: thumH,
  caption: cleanName("Mariahilf_40x40cm_Öl auf Holz"),
},


// Klopapier Ende 


// Vitamine 1
  {
    
    thumbnail: autoThum("1_vit1"),
    src: webPrefix + "1_vit1/1_vit1_800x800.png",
    thumbnailWidth: 200,
    thumbnailHeight: thumH,
    caption: cleanName("Vitamine 1 - 50x70cm Öl LW "),
    // customOverlay: (
    //   <div className="custom-overlay__caption">
    //     {/* <div >Boats (Jeshu John - designerspics.com)</div> */}
    //   </div>
    // ),
    // thumbnailCaption:"Vitamine"
    // tags: [{ value: "Ocean", title: "Ocean" }, { value: "People", title: "People" }],
  },
  {
    thumbnail:autoThum("2_vit2"),
    src: webPrefix + "2_vit2/2_vit2_800x800.png",
    thumbnailWidth: thumW,
    thumbnailHeight: thumH,
    caption: cleanName("Vitamine  2 - 30x30cm Öl LW "),
  },


  // Anfang Tiere
  {
    thumbnail: autoThum("3_dackel"),
    src: webPrefix + "3_dackel/3_dackel_800x800.png",
    thumbnailWidth: 200,
    thumbnailHeight: thumH,
    caption: cleanName("Dackelblick_ 80x100cm_Öl_LW"),
  },
  {
    thumbnail: autoThum("25_gesel"),
    src: autoSrc("25_gesel"),
    thumbnailWidth: thumW,
    thumbnailHeight: thumH,
    caption: cleanName("Goldesel"),
  },
  {
    thumbnail: autoThum("28_lilly"),
    src: autoSrc("28_lilly"),
    thumbnailWidth: thumW,
    thumbnailHeight: thumH,
    caption: cleanName("Lilly"),
  },

  
  // !! fixx -- weil bild zu breit
  {
    thumbnail: "http://isabelle-pruefling.de/img/9_weib/9_weib_thumb.png",
    src: webPrefix + "9_weib/9_weib_800x800.png",
    thumbnailWidth: 200,
    thumbnailHeight: 200,
    caption: cleanName("Weiblein_30x100cm_Acryl_LW"),
  },

  {
    thumbnail: autoThum("18_mo"),
    src: autoSrc("18_mo"),
    thumbnailWidth: thumW,
    thumbnailHeight: thumH,
    caption: cleanName("Mops"),
  },


  {
    thumbnail: autoThum("4_maus"),
    src: webPrefix + "4_maus/4_maus_800x800.png",
    thumbnailWidth: thumW,
    thumbnailHeight: thumH,
    caption: cleanName("Mausefalle_40x50cm_Öl_LW"),
  },
  {
    thumbnail: autoThum("32_dult"),
    src: autoSrc("32_dult"),
    thumbnailWidth: thumW,
    thumbnailHeight: thumH,
    caption: cleanName("Dult"),
  },

  {
    thumbnail: autoThum("31_werner"),
    src: autoSrc("31_werner"),
    thumbnailWidth: thumW,
    thumbnailHeight: thumH,
    caption: cleanName("werner"),
  },  



  {
    thumbnail: autoThum("21_buleben"),
    src: autoSrc("21_buleben"),
    thumbnailWidth: thumW,
    thumbnailHeight: thumH,
    caption: cleanName("Buntes Leben"),
  },

  {
    thumbnail: autoThum("22_emil"),
    src: autoSrc("22_emil"),
    thumbnailWidth: thumW,
    thumbnailHeight: thumH,
    caption: cleanName("Emil Nolde"),
  },




  {
    thumbnail: autoThum("24_geo"),
    src: autoSrc("24_geo"),
    thumbnailWidth: thumW,
    thumbnailHeight: thumH,
    caption: cleanName("Geometrie"),
  },


  {
    thumbnail: autoThum("26_himmel"),
    src: autoSrc("26_himmel"),
    thumbnailWidth: thumW,
    thumbnailHeight: thumH,
    caption: cleanName("Himmelstor"),
  },

  {
    thumbnail: autoThum("27_laufl"),
    src: autoSrc("27_laufl"),
    thumbnailWidth: thumW,
    thumbnailHeight: thumH,
    caption: cleanName("Laufenlassen"),
  },

  {
    thumbnail: autoThum("15_durst"),
    src: autoSrc("15_durst"),
    thumbnailWidth: 150,
    thumbnailHeight: thumH,
    caption: cleanName("Durst"),
  },

  {
    thumbnail: autoThum("29_livo"),
    src: autoSrc("29_livo"),
    thumbnailWidth: 200,
    thumbnailHeight: thumH,
    caption: cleanName("Livorno"),
  },

// alte bilder


  // {
  //   thumbnail: autoThum("30_stahl"),
  //   src: autoSrc("30_stahl"),
  //   thumbnailWidth: 200,
  //   thumbnailHeight: thumH,
  //   caption: cleanName("Stahlfaß"),
  // },



  // {
  //   thumbnail: autoThum("7_hint"),
  //   src: webPrefix + "7_hint/7_hint_800x800.png",
  //   thumbnailWidth: 200,
  //   thumbnailHeight: thumH,
  //   caption: cleanName("Hintertuxer Gletscher_40x50cm_Acryl_LW"),
  // },


  //added 5.5.2022
  // {
  //   thumbnail: autoThum("11_stein1"),
  //   src: autoSrc("11_stein1"),
  //   thumbnailWidth: 200,
  //   thumbnailHeight: thumH,
  //   caption: cleanName("Steinberger See II"),
  // },

  // {
  //   thumbnail: autoThum("12_stein2"),
  //   src: autoSrc("12_stein2"),
  //   thumbnailWidth: 200,
  //   thumbnailHeight: thumH,
  //   caption: cleanName("Steinberger See I"),
  // },

  // {
  //   thumbnail: autoThum("13_go1"),
  //   src: autoSrc("13_go1"),
  //   thumbnailWidth: 150,
  //   thumbnailHeight: thumH,
  //   caption: cleanName("Gogotte I"),
  // },

  // {
  //   thumbnail: autoThum("14_go2"),
  //   src: autoSrc("14_go2"),
  //   thumbnailWidth: 150,
  //   thumbnailHeight: thumH,
  //   caption: cleanName("Gogotte II"),
  // },


  // {
  //   thumbnail: autoThum("16_e1"),
  //   src: autoSrc("16_e1"),
  //   thumbnailWidth: 150,
  //   thumbnailHeight: thumH,
  //   caption: cleanName("Esel 1"),
  // },

  // {
  //   thumbnail: autoThum("17_e2"),
  //   src: autoSrc("17_e2"),
  //   thumbnailWidth: 150,
  //   thumbnailHeight: thumH,
  //   caption: cleanName("Esel 2"),
  // },


];
